import React, { useEffect, useState } from "react";
import CustomTable from "../reusable/CustomTable";
import classes from "./OpenEvents.module.css";
import {
  getUnregisteredEvents,
  getUpcomingEvents,
} from "../../utils/ApiFunctions";
import moment from "moment";
import Cookies from "js-cookie";
import Loader from "../reusable/Loader";
import NoData from "../reusable/NoData";

const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Format",
    accessor: "format",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "",
    accessor: "action",
  },
];

function OpenEvents() {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const [unregisteredEventIds, setUnregisteredEventIds] = useState([]);
  const employeeId = Cookies.get("userid");

  // Fetch unregistered events
  useEffect(() => {
    const fetchUnreg = async () => {
      try {
        const registeredResp = await getUnregisteredEvents(employeeId);
        if (
          registeredResp &&
          registeredResp.data &&
          registeredResp.data.events
        ) {
          const unregIds = registeredResp.data.events.map((event) => event._id);
          setUnregisteredEventIds(unregIds);
        }
      } catch (error) {
        console.error("Error fetching unregistered events", error);
      }
    };
    fetchUnreg();
  }, [employeeId]);

  // Fetch open events
  const getOpenEvents = async () => {
    setLoading(true);
    try {
      const resp = await getUpcomingEvents(page);
      if (resp && resp.data && resp.data.events) {
        const filteredEvents =
          resp.data.events
            ?.filter((event) => unregisteredEventIds.includes(event._id))
            .map((event, index) => ({
              _id: event._id,
              id: (page - 1) * 5 + index + 1,
              name: event.eventName,
              court: event?.court,
              date: moment(event.date).format("YYYY-MM-DD"),
              time: `${moment(event.fromTime).format("HH:mm")}-${moment(
                event.toTime
              ).format("HH:mm")}`,
              format: event.eventFormat,
              type: event.type,
              action: "register",
            })) || [];

        setEvents(filteredEvents);
        setTotalPages(resp.totalPages);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.error("Error fetching events", error);
      setEvents([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (unregisteredEventIds.length > 0) {
      getOpenEvents();
    }
  }, [unregisteredEventIds, page]);

  if (loading) {
    return <Loader />;
  }

  if (events.length === 0) {
    return (
      <NoData
        title={"No Open Events"}
        subtitle={"Wait for the events to be created"}
      />
    );
  }

  return (
    <div className={classes.container}>
      <CustomTable
        responsive={"tableTabletEmployeeEvents"}
        columns={columns}
        data={events}
      />
      {/* Pagination can be added here if needed */}
      {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
    </div>
  );
}

export default OpenEvents;
