import React, { useEffect, useRef, useState } from "react";
import callicon from "../../../images/calliconblack.svg";
import approveicon from "../../../images/approveicon.svg";
import declineicon from "../../../images/declineicon.svg";
import editIcon from "../../../images/edit-icon.svg";
import deleteIcon from "../../../images/deleteicon.svg";
import eventmanagericon from "../../../images/eventmanagericon.svg";
import techmanagericon from "../../../images/techmanagericon.svg";
import classes from "./EmployeeOptions.module.css";
import Approvepopup from "./Approvepopup";
import DeclinePopup from "./DeclinePopup";
import AssignasOptions from "./AssignasOptions";
import AddEmployeePopup from "./AddEmployeePopup";
import CancelPopup from "../../employee/CancelPopup";
import { updateEvent } from "../../../utils/ApiFunctions";
import { updateRegistration } from "../../../utils/ApiFunctions";

function EmployeeOptions({ phoneNo, data, update, setUpdate }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Store selected employee for editing
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleEditOpen = () => {
    setEditDialogOpen(true);
    setSelectedEmployee(data); // Set the selected employee data for editing
    console.log("data", data);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };
  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const [declineOpen, setDeclineOpen] = useState(false);
  const handleDeclineOpen = () => {
    setDeclineOpen(true);
  };
  const handleDeleteOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleDeclineClose = () => {
    setDeclineOpen(false);
  };
  const [assignoptionsOpen, setassignOptionsOpen] = useState(true);
  const boxRef = useRef(null);
  const [optionsOpen, setOptionsOpen] = useState(true);
  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setOptionsOpen(false);
    }
  };
  const eventClickHandler = async () => {
    const resp = await updateEvent(data.eventid, {
      eventManager: data.eventManager === data.name ? null : data.name,
    });
    if (resp) {
      await updateRegistration(data._id, {
        isEventManager: data.eventManager !== data.name,
      });
      setassignOptionsOpen(false);
      setUpdate(!update);
    }
  };
  const techClickHandler = async () => {
    const resp = await updateEvent(data.eventid, {
      techManager: data.techManager === data.name ? null : data.name,
    });
    if (resp) {
      await updateRegistration(data._id, {
        isTechManager: data.techManager !== data.name,
      });
      setassignOptionsOpen(false);
      setUpdate(!update);
    }
  };
  useEffect(() => {
    if (!dialogOpen && !declineOpen) {
      document.addEventListener("click", handleBackdropClick, true);
      return () => {
        document.removeEventListener("click", handleBackdropClick, true);
      };
    }
    if (dialogOpen || declineOpen) {
      setOptionsOpen(false);
    }
  }, [dialogOpen, declineOpen]);

  useEffect(() => {
    if (selectedEmployee) {
    }
  }, [selectedEmployee]);

  return (
    <>
      {optionsOpen && (
        <>
          <div className={classes.boxcontainer} ref={boxRef}>
            <div className={classes.box}>
              <div className={classes.btngrp}>
                <span className={classes.btn}>
                  <img src={callicon} className={classes.icon} />
                  <p className={classes.one}>{phoneNo}</p>
                </span>

                {data.status !== "Approved" && (
                  <>
                    <a href="#" className={classes.btn} onClick={handleOpen}>
                      <img src={approveicon} className={classes.icon} />
                      <p className={classes.two}>Approve</p>
                    </a>
                  </>
                )}
                {data?.status === "Approved" && (
                  <>
                    <a
                      href="#"
                      className={classes.btn}
                      // onClick={eventClickHandler}
                      onClick={(e) => {
                        e.preventDefault();
                        eventClickHandler();
                      }}
                    >
                      <img src={eventmanagericon} className={classes.icon} />
                      <p className={classes.three}>
                        {data?.eventManager === data?.name
                          ? "Remove as Event Manager"
                          : "Mark as Event Manager"}{" "}
                      </p>
                    </a>
                    <a
                      href="#"
                      className={classes.btn}
                      // onClick={techClickHandler}
                      onClick={(e) => {
                        e.preventDefault();
                        techClickHandler();
                      }}
                    >
                      <img src={techmanagericon} className={classes.icon} />
                      <p className={classes.four}>
                        {data?.techManager === data?.name
                          ? "Remove as Tech Manager"
                          : "Mark as Tech Manager"}{" "}
                      </p>
                    </a>
                  </>
                )}
                {data.status !== "Declined" && (
                  <>
                    <a
                      href="#"
                      className={classes.btn}
                      // onClick={handleDeclineOpen}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeclineOpen();
                      }}
                      
                    >
                      <img src={declineicon} className={classes.icon} />
                      <p className={classes.three}>Decline</p>
                    </a>
                  </>
                )}
                {/* Always display the Edit option */}
                <a href="#" className={classes.btn}
                //  onClick={handleEditOpen}
                 onClick={(e) => {
                  e.preventDefault();
                  handleEditOpen(); 
                }}
                 >
                  <img src={editIcon} className={classes.icon} />
                  <p className={classes.four}>Edit</p>
                </a>
                <a href="#" className={classes.btn}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteOpen();
                  }}
                //  onClick={handleDeleteOpen}
                 >
                  <img src={deleteIcon} className={classes.icon} />
                  <p className={classes.three}>Delete</p>
                </a>
              </div>
            </div>
          </div>

          {/* {data.status === "Approved" && (
            <AssignasOptions
              data={data}
              update={update}
              setUpdate={setUpdate}
              assignoptionsOpen={assignoptionsOpen}
              setassignOptionsOpen={setassignOptionsOpen}
              setOptionsOpen={setOptionsOpen}
            />
          )} */}
        </>
      )}
      <Approvepopup
        open={dialogOpen}
        handleClose={handleClose}
        courtData={data.courtData}
        regid={data._id}
        transportation={data.transportation}
        email={data.email}
        name={data.name}
        update={update}
        setUpdate={setUpdate}
        setOptionsOpen={setOptionsOpen}
        assignoptionsOpen={assignoptionsOpen}
        setassignOptionsOpen={setassignOptionsOpen}
      />
      <DeclinePopup
        open={declineOpen}
        handleClose={handleDeclineClose}
        regid={data._id}
        data={data}
        update={update}
        setUpdate={setUpdate}
        setOptionsOpen={setOptionsOpen}
        setassignOptionsOpen={setassignOptionsOpen}
      />
      <AddEmployeePopup
        open={editDialogOpen}
        handleClose={handleEditClose}
        eventid={data.eventId} // Ensure you pass the event ID
        courtData={data.courtData} // Pass the court data
        update={update}
        setUpdate={setUpdate}
        employeeData={selectedEmployee}
        editMode={true}
      />
      <CancelPopup
        open={deleteDialogOpen}
        handleClose={handleDeleteClose}
        id={data.eventid}
        empID={data.employeeID}
        title={"Delete"}
        update={update}
        setUpdate={setUpdate}
      />
    </>
  );
}

export default EmployeeOptions;
