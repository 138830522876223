import React, { useEffect, useState } from "react";
import backicon from "../../../images/back.svg";
import calendaricon from "../../../images/calendaricon.svg";
import timeicon from "../../../images/timeicon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import classes from "../../admin/events comps/LocalEventDetails/LocalEventDetails.module.css";
import { useSelector } from "react-redux";
import {
  getCustomEvents,
  getOneEvent,
  updateEvent,
  getregisterations
} from "../../../utils/ApiFunctions";
import { Tab, Tabs, Box } from "@mui/material";
import Loader from "../../reusable/Loader";
import {
  a11yProps,
  CustomTabPanel,
} from "../../admin/events comps/LocalEventDetails/LocalEventDetails";
import LocalEventCard from "../../admin/events comps/LocalEventDetails/LocalEventCard";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import EndEventPopup from "../EndEventPopup";
import RemarkPopup from "../RemarkPopup";
import CustomTable from "../../reusable/CustomTable";
import EmployeeOptions from "../../admin/events comps/EmployeeOptions";
import exportToExcel from "../../reusable/exportToExcel";
import AddEmployeePopup from "../../admin/events comps/AddEmployeePopup";


const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Employee",
    accessor: "name",
  },
  {
    Header: "",
    accessor: "RoleIndicator",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Transportation",
    accessor: "transportationStatus",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "",
  },
];
const LocalEventManager = () => {
  const { id } = useParams();
  const [update, setUpdate] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [data, setData] = useState([{}]);
  const [localCourts, setLocalCourts] = useState([]);
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState([]);
  // const [customEventDetails, setCustomEventDetails] = useState([]);
  const [daytime, setDayTime] = useState("");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [endEventState, setEndEventState] = useState(false);
  const [filteredCourt, setFilteredCourt] = useState([]);
  console.log("eventdetails", eventDetails)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleAddEmployeeOpen = () => {
    setAddDialogOpen(true);
  }
  const handleAddEmployeeClose = () => {
    setAddDialogOpen(false);
  }


  const getRegs = async () => {
    setLoading(true);
    const resp = await getregisterations(id);
    if (resp) {
      const temp = resp?.data?.registeredEmployees?.map((reg, index) => {
        let roleIndicator = "";
        if (reg.event?.techManager === reg.employee?.name)
          roleIndicator += "⚙️";
        if (reg.event?.eventManager === reg.employee?.name)
          roleIndicator += "👑";

        return {
          _id: reg._id,
          id: index + 1,
          name: reg.employee.name,
          shortLead: reg?.event?.lead,
          employeeID: reg.employee._id,
          address: reg.employee.address,
          RoleIndicator: roleIndicator ? roleIndicator : "",
          phoneNo: reg?.employee?.phoneNo,
          email: reg?.employee?.email,
          court: reg?.court?.name || "NA",
          transportation: reg?.transportation ? "Requested" : "Refused",
          transportationStatus:
            reg?.transportationStatus !== undefined
              ? reg.transportationStatus
                ? "Approved"
                : "Declined"
              : reg?.transportation
                ? "Requested"
                : "-",
          status:
            reg.registrationStatus === true
              ? "Approved"
              : reg.registrationStatus === false
                ? "Declined"
                : "Pending",
          // courtData: reg?.event?.court,
          courtData: localCourts,
          eventid: id,
          techManager: reg.event?.techManager || null,
          eventManager: reg.event?.eventManager || null,
        };
      });
      setData(temp);
      setLoading(false);
    }
    setLoading(false);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const to = useSelector((state) => state.link.to);

  useEffect(() => {
    const fetchEventDetails = async () => {
      setLoading(true);
      const resp = await getOneEvent(id);
      if (resp) {
        const eventData = resp.data.data;
        setEventDetails(eventData);
        // setLead(eventData.lead);
        filterCourts(eventData.groups);
        setDayTime({
          day: moment(eventData.date).format("dddd"),
          monthYear: moment(eventData.date).format("DD MMMM YYYY"),
          time: `${moment(eventData.fromTime).format("HH:mm")}-${moment(
            eventData.toTime
          ).format("HH:mm")}`,
        });
        setLoading(false);
      }
    };
    fetchEventDetails();
  }, [id]);

  useEffect(() => {
    // getEventDetails();

    getRegs();
  }, [update]);

  const filterCourts = (groups) => {
    if (!groups || !Array.isArray(groups)) {
      console.error("Invalid or undefined groups", groups);
      return;
    }

    const availableCourts = [];

    groups.forEach((group) => {
      // Check if timeSlots exists and is an array
      if (group.timeSlots && Array.isArray(group.timeSlots)) {
        group.timeSlots.forEach((slot) => {
          if (slot.court && !availableCourts.includes(slot.court)) {
            availableCourts.push(slot.court);
          }
        });
      }
    });

    // Filter the available courts from the predefined tabsData
    const courtData = tabsData.filter((tab) =>
      availableCourts.includes(tab.title)
    );
    setFilteredCourt(courtData);
    setLocalCourts(courtData.map(item => ({
      name: item.title
    })))
  };

  const handleEndEvent = async () => {
    const resp = await updateEvent(id, {
      eventEnded: true,
    });
    if (resp) {
      setEndEventState(!endEventState);
      handleClose();
    }
  };

  const handleRemarkOpen = () => {
    setRemarkOpen(true);
  };

  const handleRemarkClose = () => {
    setRemarkOpen(false);
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "Poppins, sans-serif",
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.75)",
      "&.Mui-selected": {
        color: "#DB545D",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#DB545D",
      },
    })
  );

  // const getCustomEventDetails = async () => {
  //   setLoading(true);
  //   const resp = await getCustomEvents("Local");
  //   if (resp) {
  //     // console.log("event format data: ", resp);
  //     setCustomEventDetails(resp.data);
  //     // setLead(resp?.data?.lead);
  //     setLoading(false);
  //   }
  // };

  // const data = [
  //   {
  //     eventName: "NewEvent",
  //     eventFormat: "Local",
  //     status: "Paid",
  //     date: "2023-08-03T00:00:00.000Z",
  //     time: [
  //       {
  //         id: 1,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 2,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 3,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //     ],
  //     gender: "Mix",
  //     ageGroup: ["Mix"],
  //     court: "007",
  //     payment: "Online",
  //     amount: 500,
  //     food: [
  //       "burger",
  //       "pizza",
  //       "tapas",
  //       "snacks",
  //       "beer",
  //       "popcorn",
  //       "salads",
  //       "total combo",
  //     ],
  //     contactPerson: "Jonh Doe",
  //     contactNumber: 9876543210,
  //     contactEmail: "johndoe@gmail.com",
  //   },
  //   {
  //     eventName: "NewEvent",
  //     eventFormat: "Local",
  //     status: "Paid",
  //     date: "2023-08-03T00:00:00.000Z",
  //     time: [
  //       {
  //         id: 1,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 2,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 3,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //     ],
  //     gender: "Mix",
  //     ageGroup: ["Mix"],
  //     court: "007",
  //     payment: "Online",
  //     amount: 500,
  //     food: [
  //       "burger",
  //       "pizza",
  //       "tapas",
  //       "snacks",
  //       "beer",
  //       "popcorn",
  //       "salads",
  //       "total combo",
  //     ],
  //     contactPerson: "Jonh Doe",
  //     contactNumber: 9876543210,
  //     contactEmail: "johndoe@gmail.com",
  //   },
  // ];

  const remark = eventDetails?.managerRemark;

  const tabsData = [
    {
      id: 0,
      title: "Gold 1",
    },
    {
      id: 1,
      title: "Gold 2",
    },
    {
      id: 2,
      title: "007",
    },
    {
      id: 3,
      title: "747",
    },
    {
      id: 4,
      title: "Double",
    },
    {
      id: 5,
      title: "Add1",
    },
    {
      id: 6,
      title: "Add2",
    },
  ];

  // useEffect(() => {
  //   setDayTime((prev) => ({
  //     day: moment(data?.date).format("dddd"),
  //     monthYear: moment(data?.date).format("DD MMMM YYYY"),
  //     time: `${moment(data?.fromTime).format("HH:mm")}-${moment(
  //       data?.toTime
  //     ).format("HH:mm")}`,
  //   }));
  // }, []);

  // useEffect(() => {
  //   // getEventDetails();
  //   getCustomEventDetails();
  //   // getRegs();
  // }, [id]);
  return (
    <>
      {loading && <Loader />}
      {!loading && eventDetails && (
        <div>
          <div className={classes.titlecontainer}>
            <div className={classes.detailscontainer}>
              <div onClick={() => navigate(-1)}>
                <div className={classes.back}>
                  <img src={backicon} alt="" />
                </div>
              </div>
              <div className={classes.eventdetailscontainer}>
                <div>
                  <h2 className={classes.title}>{eventDetails.eventName}</h2>
                  {/* <h2 className={classes.title}>{item?.eventName}</h2> */}
                  <div className={classes.detailscontainer}>
                    <p>
                      <img
                        src={calendaricon}
                        alt=""
                        className={classes.iconstop}
                      />
                      {daytime.monthYear}
                    </p>
                    <p>
                      <img src={timeicon} alt="" className={classes.iconstop} />
                      {daytime.time}
                    </p>
                    <p>
                      <img
                        src={calendaricon}
                        alt=""
                        className={classes.iconstop}
                      />
                      {daytime.day}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className={classes.btncontainer}>
              <Button className={classes.btn} onClick={handleOpen}>
                End Event
              </Button>
              {eventDetails.eventEnded && (
                <>
                  {/* <div className={classes.btnended}>Event Ended</div> */}
                  {!eventDetails?.adminRemark && (
                    <Button
                      className={classes.btn}
                      onClick={handleRemarkOpen}
                    >
                      Remark
                    </Button>
                  )}
                </>
              )}
              {/* {item?.paymentStatus ? item?.paymentStatus : <div>Pending</div>} */}
            </p>
          </div>
          <Box sx={{ width: "100%", padding: "2rem 2.4rem" }}>

            {/* {tabsData.map((item) => {
              const newArr = [];
              customEventDetails.filter((i) => {
                i.court.includes(item.title) && newArr.push(i);
                return newArr;
              });
              // console.log(item.title, " : ", newArr);
              return (
                <CustomTabPanel value={value} index={item.id}>
                  <LocalEventCard data={newArr} />
                </CustomTabPanel>
              );
            })} */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    textColor="#DB545D"
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: "#DB545D",
                      },
                    }}
                  >
                    {filteredCourt.map((tab, index) => (
                      <StyledTab
                        key={index}
                        label={tab.title}
                        {...a11yProps(index)}
                        sx={{ fontSize: 16, fontWeight: 600 }}
                      />
                    ))}
                  </Tabs>
                </Box>
                {filteredCourt.map((tab, index) => (
                  <CustomTabPanel value={value} index={index} key={index}>
                    <LocalEventCard
                      court={tab.title}
                      data={eventDetails.groups.filter((group) =>
                        group.timeSlots.some((slot) => slot.court === tab.title)
                      )}
                    />
                  </CustomTabPanel>
                ))}
              </div>
              <div className={classes.employeetablecontainer} style={{ width: "50%" }}>
                <CustomTable
                  responsive={"viewEmployeeTabletTable"}
                  columns={columns}
                  data={data ? data : null}
                  OptionsComponent={EmployeeOptions}
                  status={true}
                  update={update}
                  setUpdate={setUpdate}
                />
                {(!eventDetails?.data?.eventEnded ||
                  eventDetails.data.eventEnded === undefined) && (
                    <>
                      <button className={classes.newempbtn} onClick={handleAddEmployeeOpen}>
                        Add Employee
                      </button>
                    </>
                  )}
              </div>
            </div>
          </Box>
        </div>
      )}
      {!eventDetails.eventEnded && (
        <EndEventPopup
          open={dialogOpen}
          handleClose={handleClose}
          handleEndEvent={handleEndEvent}
          endEventState={endEventState}
          setEndEventState={setEndEventState}
        />
      )}
      <RemarkPopup
        open={remarkOpen}
        handleClose={handleRemarkClose}
        id={id}
        update={update}
        setUpdate={setUpdate}
      />
      <AddEmployeePopup
        open={addDialogOpen}
        handleClose={handleAddEmployeeClose}
        eventid={id}
        courtData={localCourts}
        update={update}
        setUpdate={setUpdate}
      />
    </>
  );
};

export default LocalEventManager;
